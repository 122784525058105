import { CircularProgress } from '@mui/material';

import styles from './styles.module.css';
import { withStyledComponents } from './with-styled-component';

const Center = withStyledComponents(styles.Center, 'div');

const CenteredProgress = ({ className, style, size }: { className?: string; style?: any; size?: number }) => (
  <Center className={className}>
    <CircularProgress style={style} size={size ?? 50} />
  </Center>
);

export default CenteredProgress;
